<template>
  <div>
    <slot />
  </div>
</template>

<script>
export default {
  transition: {
    name: 'fadeBlur',
    mode: 'out-in'
  }
}
</script>

<style media="screen">
.about_bg h1 {
  text-shadow: black -0.5px 3px;
}

.navbar-collapse.collapse:not(.show) a.nav-link {
  text-shadow: black 0px 2px;
}

/* Transição de fade com desfoque */
.fadeBlur-enter-active,
.fadeBlur-leave-active {
  transition: all 0.75s ease-in-out;
  filter: blur(0.5px); /* Leve desfoque durante a transição */
}
.fadeBlur-enter-from,
.fadeBlur-leave-to {
  opacity: 0;
}
</style>
